import { post, fetch } from "../axios";
import host from "../config";

export default {
  // ========报表start==========================

  //获取标签数据接口
  getTag() {
    return fetch(`${host}/goods/list?gc_id_1=3&gc_id_2=0&charge_paper=0&seek_title=&flag=&offset=0&limit=4&courseType=10&teacherId=undefined`);
  },
  // 获取平台接口
  getPlatform: function (data = {}) {
    return fetch(`${host}/ypd/report/platform`, data);
  },
  // 导入
  importrp: function (data = {}) {
    return post(`${host}/ypd/report/importrp`, data);
  },
  //获取分公司接口
  getCompany: function (data = {}) {
    return fetch(`${host}/ypd/report/company`, data);
  },
  // 获得首页数据
  reportlist: function (data = {}) {
    return fetch(`${host}/ypd/report/reportlist`, data);
  },
  // 是否能导入
  importpower() {
    return fetch(`${host}/ypd/report/importpower`);
  },
  // 导入列表
  importlist: function (data = {}) {
    return fetch(`${host}/ypd/report/importlist`, data);
  },

  // 进线量接口 参数获取当前报表列表参数
  income: function (data = {}) {
    return fetch(`${host}/ypd/report/income`, data);
  },

  // 进线量详情接口 参数获取当前报表列表参数
  incomedetail: function (data = {}) {
    return fetch(`${host}/ypd/report/incomedetail`, data);
  },

  // 有效率接口 参数获取当前报表列表参数
  effectrate: function (data = {}) {
    return fetch(`${host}/ypd/report/effectrate`, data);
  },

  // 统计列表
  reportCumulative: function (data = {}) {
    return fetch(`${host}/ypd/report/cumulative`, data);
  },
  // 平均列表
  reportRatio: function (data = {}) {
    return fetch(`${host}/ypd/report/ratio`, data);
  },
  // 列表详情
  reportDetail: function (data = {}) {
    return fetch(`${host}/ypd/report/detail`, data);
  },
  // 比率
  ratiodetail: function (data = {}) {
    return fetch(`${host}/ypd/report/ratiodetail`, data);
  },
  roiratio: function (data = {}) {
    return fetch(`${host}/ypd/report/roiratio`, data);
  },
  // ========报表end==========================
};
