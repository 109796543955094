import Qs from 'qs';


function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

function getWeek(date) {
  var week;
  if (date.getDay() == 0) week = "周日"
  if (date.getDay() == 1) week = "周一"
  if (date.getDay() == 2) week = "周二"
  if (date.getDay() == 3) week = "周三"
  if (date.getDay() == 4) week = "周四"
  if (date.getDay() == 5) week = "周五"
  if (date.getDay() == 6) week = "周六"
  return week;
}

export default {
  // 时间段
  longTime(time1, time2, fmt, con) {
    var con = con || ' - '
    var date1 = new Date(time1 * 1000);
    var date2 = new Date(time2 * 1000);
    var fmt2 = fmt;
    if (/(y+)/.test(fmt)) {
      if (date1.getFullYear() != date2.getFullYear()) {
        return formatDate(time1, fmt) + con + formatDate(time2, fmt);
      }
      fmt2 = fmt2.replace(RegExp.$1, '');
    }
    var arr1 = this.getFormatDateArr(date1, fmt);
    var arr2 = this.getFormatDateArr(date2, fmt);
    var fmt1 = arr1.year;
    var flag = false;
    for (let k in arr1.o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = arr1.o[k] + '';
        fmt1 = fmt1.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        let str2 = arr2.o[k] + '';
        if (str != str2 || flag || k == 'h+') {
          if (!flag) {
            flag = true;
            var re = new RegExp(`^.+(?=(${k}))`);
            fmt2 = fmt2.replace(re, '');
          }
          fmt2 = fmt2.replace(RegExp.$1, (RegExp.$1.length === 1) ? str2 : padLeftZero(str2));
        }
      }
    }
    return fmt1 + con + fmt2;
  },
  //获取时间数组
  getFormatDateArr(date, fmt) {
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'W+': getWeek(date),
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    return {year: fmt, o: o}
  },
  //倒数计时时间
  countdownTime(time, fmt) {
    var h = parseInt(time / 3600);
    var m = parseInt((time % 3600) / 60);
    var s = (time % 3600) % 60;
    let o = {
      'h+': h,
      'm+': m,
      's+': s,
    };
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + '';
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
      }
    }
    return fmt;
  },
  // 时间搓
  formatDate(time, fmt) {
    var date = new Date(time * 1000);
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'W+': getWeek(date),
    };
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + '';
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
      }
    }
    return fmt;
  },
  //获取链接参数
  getParams() {
    let search = location.search
    return Qs.parse(search)
  },
  //设置标题
  setTitle(title) {
    document.getElementsByTagName('title')[0].textContent = title;
  },
  scrollTop(el, from = 0, to, duration = 1000, endCallback) {
    if (!window.requestAnimationFrame) {
      window.requestAnimationFrame = (
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (callback) {
          return window.setTimeout(callback, 1000 / 60);
        }
      );
    }
    const difference = Math.abs(from - to);
    const step = Math.ceil(difference / duration * 50);

    function scroll(start, end, step) {
      if (start === end) {
        endCallback && endCallback();
        return;
      }

      let d = (start + step > end) ? end : start + step;
      if (start > end) {
        d = (start - step < end) ? end : start - step;
      }

      if (el === window) {
        window.scrollTo(d, d);
      } else {
        el.scrollTop = d;
      }
      window.requestAnimationFrame(() => scroll(d, end, step));
    }

    scroll(from, to, step);
  },
  /* 判断浏览器类型 */
  parseUA() {
    var u = navigator.userAgent;
    var u2 = navigator.userAgent.toLowerCase();
    return { //移动终端浏览器版本信息
      trident: u.indexOf('Trident') > -1, //IE内核
      presto: u.indexOf('Presto') > -1, //opera内核
      webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
      iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf('iPad') > -1, //是否iPad
      webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
      iosv: u.substr(u.indexOf('iPhone OS') + 9, 3),
      weixin: u2.match(/MicroMessenger/i) == "micromessenger",
      ali: u.indexOf('AliApp') > -1,
    };
  },
  customerLink(){
    return 'http://p.qiao.baidu.com/cps/chat?siteId=14178608&userId=28213394&siteToken=02afce7e79450146eaf69446d23d64e0&cp=&cr=&cw=%E7%AB%8B%E5%8D%B3%E5%92%A8%E8%AF%A2%E6%8C%89%E9%92%AE';
    // location.href =
  }
}
