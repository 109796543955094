let development = process.env.NODE_ENV == 'development' ? true : false;
let pro = location.protocol

// const host = 'http://192.168.16.21:8089'
const host = pro + '//sadmin.youpudao.com/api'
// const devHost = 'http://sadmin.youpudao.com/api'; // 测试
const devHost = 'http://192.168.18.78:8090/api'; // 测试

let url = ""

if (!development) {
  url = host
} else {
  url = devHost
}
export default url;