<template>
  <!-- @mousemove="move($event)" -->
  <div
    class="add-box"
    id="backHome"
    @click.stop="showAction"
    @mousedown.stop="start($event)"
    @touchstart.stop="start($event)"
    @touchmove.stop="move($event)"
    @mouseup.stop="end($event)"
    @touchend.stop="end($event)"
  >
    <div
      class="img-box"
      :style="{
        backgroundImage:
          'url(' + (show ? actionList[0].active : actionList[0].icon) + ')',
      }"
      :class="{ 'img-active': show }"
    ></div>
    <div class="action px-2" @click.stop :class="{ show: show }">
      <div class="pt-2 pb-5">
        <p
          class="py-2"
          v-for="(item, index) in actionList"
          @click="clickEvent(item.type)"
          :key="index"
        >
          {{ item.name }}
        </p>
      </div>
    </div>
  </div>
</template>

 <script>
export default {
  data() {
    return {
      show: false,
      icon: require("@img/marketing/action.png"),
      iconActive: require("@img/marketing/action-active.png"),
      position: { x: 0, y: 0 }, // 鼠标点击的x轴和y轴的距离
      nx: "", // 鼠标当前距离元素的左侧距离
      ny: "", // 鼠标当前距离元素的顶部距离
      dx: "", // 元素距离左侧的距离
      dy: "", // 元素距离顶部的距离
      xPum: "", // 元素移动的x轴距离
      yPum: "", // 元素移动的y轴距离
    };
  },
  props: {
    actionList: {
      type: Array,
      default: [
        {
          icon: require("@img/marketing/home.png"),
          active: require("@img/marketing/home.png"),
          type: "backHome",
          name: "返回主页",
        },
      ],
    },
    more: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showAction() {
      if (!this.more) return this.clickEvent(this.actionList[0].type);
      this.show = !this.show;
      this.$emit("showAction", this.show);
    },
    clickEvent(type) {
      this.$router.push({
        path: "/marketing",
      });
    },
    start(e) {
      let _this = this;
      // 如果touches存在就说明是移动端
      // 否则为pc端直接获取事件源对象
      let touch = e.touches ? e.touches[0] : e;
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = backHome.offsetLeft;
      this.dy = backHome.offsetTop;
      //解决鼠标移动过快的问题
      document.onmousemove = function (ev) {
        _this.move(ev);
      };
    },
    move(e) {
      let touch = e.touches ? e.touches[0] : e;
      this.nx = touch.clientX - this.position.x;
      this.ny = touch.clientY - this.position.y;
      this.xPum = this.dx + this.nx;
      this.yPum = this.dy + this.ny;
      backHome.style.left = this.xPum + "px";
      backHome.style.top = this.yPum + "px";
      document.addEventListener(
        "touchmove",
        function () {
          event.preventDefault();
        },
        { passive: false }
      );
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        window.event.returnValue == false;
      }
    },
    end(e) {
      let oWidth = backHome.offsetWidth; // Element Width
      let oWrapWidth = document.body.offsetWidth; // Parent Element Width
      let oWrprapHeight = document.body.offsetHeight; // Parent Element Height
      let sumWidth = backHome.offsetLeft + oWidth; // Element Left + Element Width
      let sumHeight = backHome.offsetTop + backHome.offsetHeight; // Element Top + Element Height

      // The Limit Deal
      if (backHome.offsetLeft < 0) {
        backHome.style.left = 0;
      } else if (sumWidth > oWrapWidth) {
        backHome.style.left = oWrapWidth - oWidth + "px";
      } else if (backHome.offsetTop < 0) {
        backHome.style.top = 0;
      } else if (sumHeight > oWrprapHeight) {
        backHome.style.top = oWrprapHeight - backHome.offsetHeight + "px";
      }
      document.onmousemove = null;
      document.onmouseup = null;
    },
  },
};
</script>

<style lang='scss' scoped>
.add-box {
  position: fixed;
  right: 4%;
  bottom: 140px;
  z-index: 500;
  width: 102px;
  height: 102px;
  border-radius: 50%;
  @include flex-center(column);
  cursor: pointer;
  opacity: 0.9;

  // & > img {
  //   width: 100%;
  //   height: 100%;
  //   position: relative;
  //   z-index: 2;
  // }
  .img-box {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    background: url("~@img/marketing/action.png") no-repeat;
    background-size: cover;
    transition: all 0.3s linear;
    box-shadow: 0px 0px 9px 0px rgba(60, 60, 60, 0.36);
    border-radius: 50%;
  }
  .img-active {
    background: url("~@img/marketing/action-active.png") no-repeat;
    background-size: cover;
    transition: all 0.3s linear;
  }
  .action {
    position: absolute;
    bottom: 50px;
    z-index: 1;
    width: 102px;
    height: 0;
    overflow: hidden;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 0px 9px 0px rgba(60, 60, 60, 0.18);
    border-radius: 51px 51px 0px 0px;
    & > div {
      & > p {
        font-size: 24px;
        color: #191919;
        text-align: center;
      }
      & > p:not(:last-child) {
        border-bottom: 2px solid #f0f0f0;
      }
    }
  }
  .show {
    height: auto;
    transition: all 0.3s linear;
  }
}
</style>