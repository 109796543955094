<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component
          :key="$route.name"
          :is="Component"
          v-if="$route.meta.keepAlive"
        />
      </keep-alive>
      <component
        :key="$route.name"
        :is="Component"
        v-if="!$route.meta.keepAlive"
      />
      <backhome v-if="$route.meta.goback"></backhome>
    </router-view>
  </div>
</template>
<script>
import "@/assets/css/public.css";
import backhome from "@/components/marketing/backhome.vue";
export default {
  components: {
    backhome,
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $fontColor;
  min-height: $full;
  box-sizing: border-box;
  position: relative;
  // background-color: $pageColor;
}

.isPadding {
  padding-top: $headerHeight;
  padding-bottom: $footerHeight;
}

.fullContainer {
  min-height: 600px;
}

.bigContainer {
  width: 100%;
  min-width: $fContainer;
}

.middleContainer {
  width: $fContainer;
  margin: 0 auto;
}

.smallContainer {
  width: $cContent;
  margin: 0 auto;
}

.swiperContainer {
  min-width: $fContainer;
  position: relative;
}

.pageComponentsBox {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*infinite-loading*/
.no-more {
  font-size: $commonFontSize;
  color: $titleColor;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
