/**
 * Created by whiter on 2019/9/26.
 * 营销crm路由
 */

export default [
  // 营销crm PC首页
  {
    path: "/pc_marketing",
    name: "pc_marketing",
    component: () => import("@/views/marketing/pc/index"),
    meta: {
      title: "首页",
      color: "#ffffff",
      source: "saleclue",
    },
  },
  {
    path: "/marketingCount",
    name: "marketingCount",
    component: () => import("@/views/marketing/pc/marketingCount"),
    meta: {
      title: "首页",
      color: "#ffffff",
      source: "saleclue",
    },
  },
  {
    path: "/customerCount",
    name: "customerCount",
    component: () => import("@/views/marketing/pc/customerCount"),
    meta: {
      title: "首页",
      color: "#ffffff",
      source: "saleclue",
    },
  },
  {
    path: "/followCount",
    name: "followCount",
    component: () => import("@/views/marketing/pc/followCount"),
    meta: {
      title: "首页",
      color: "#ffffff",
      source: "saleclue",
    },
  },
  // 营销crm首页
  {
    path: "/marketing",
    name: "marketing",
    component: () => import("@/views/marketing/index"),
    meta: {
      title: "首页",
      color: "#F7F8FA",
      keepAlive: false,
      source: "saleclue",
    },
  },
  // 线索
  {
    path: "/mClue",
    name: "mClue",
    component: () => import("@/views/marketing/mClue/mClue"),
    meta: {
      title: "线索",
      color: "#F7F8FA",
      goback: true,
      keepAlive: true,
    },
  },
  {
    path: "/mClueDetail",
    name: "mClueDetail",
    component: () => import("@/views/marketing/mClue/mClueDetail"),
    meta: {
      title: "线索详情",
      color: "#F7F8FA",
      source: "saleclue",
      goback: true,
    },
  },
  {
    path: "/mClueSign",
    name: "mClueSign",
    component: () => import("@/views/marketing/mClue/mClueSign"),
    meta: {
      title: "添加客户",
      color: "#F7F8FA",
      goback: true,
    },
  },
  {
    path: "/mClueAdd",
    name: "mClueAdd",
    component: () => import("@/views/marketing/mClue/mClueAdd"),
    meta: {
      title: "添加线索",
      color: "#F7F8FA",
      goback: true,
    },
  },
  {
    path: "/customer",
    name: "customer",
    component: () => import("@/views/marketing/customer/customer"),
    meta: {
      title: "客户",
      color: "#F7F8FA",
      goback: true,
      keepAlive: true,
    },
  },
  {
    path: "/customerDetail",
    name: "customerDetail",
    component: () => import("@/views/marketing/customer/customerDetail"),
    meta: {
      title: "客户详情",
      color: "#F7F8FA",
      goback: true,
    },
  },
  {
    path: "/mContract",
    name: "mContract",
    component: () => import("@/views/marketing/customer/contract"),
    meta: {
      title: "合同详情",
      color: "#F7F8FA",
      goback: true,
    },
  },
  {
    path: "/contractBack",
    name: "contractBack",
    component: () => import("@/views/marketing/customer/contractBack"),
    meta: {
      title: "退单/调减",
      color: "#F7F8FA",
      goback: true,
    },
  },

  {
    path: "/customerAdd",
    name: "customerAdd",
    component: () => import("@/views/marketing/customer/customerAdd"),
    meta: {
      title: "客户详情",
      color: "#F7F8FA",
      goback: true,
    },
  },
  {
    path: "/highSeas",
    name: "highSeas",
    component: () => import("@/views/marketing/highSeas/highSeas"),
    meta: {
      title: "公海池",
      color: "#F7F8FA",
      goback: true,
      keepAlive: true,
    },
  },
  {
    path: "/target",
    name: "target",
    component: () => import("@/views/marketing/target/target"),
    meta: {
      title: "团队目标",
      color: "#F7F8FA",
      goback: false,
    },
  },
  {
    path: "/mTargetAdd",
    name: "mTargetAdd",
    component: () => import("@/views/marketing/target/mTargetAdd"),
    meta: {
      title: "团队目标",
      color: "#F7F8FA",
      goback: true,
    },
  },

  {
    path: "/statistics",
    name: "statistics",
    component: () => import("@/views/marketing/statistics/statistics"),
    meta: {
      title: "统计",
      color: "#ffffff",
      goback: true,
    },
  },
  {
    path: "/agent",
    name: "agent",
    component: () => import("@/views/marketing/agent/agent"),
    meta: {
      title: "待办事项",
      color: "#F7F8FA",
      source: "saleclue",
      goback: true,
    },
  },
  {
    path: "/approve",
    name: "approve",
    component: () => import("@/views/marketing/agent/approve"),
    meta: {
      title: "审批",
      color: "#F7F8FA",
      source: "saleorder",
      goback: true,
    },
  },
  {
    path: "/approveDetail",
    name: "approveDetail",
    component: () => import("@/views/marketing/agent/approveDetail"),
    meta: {
      title: "审批详情",
      color: "#F7F8FA",
      goback: true,
    },
  },
  {
    path: "/collectDetail",
    name: "collectDetail",
    component: () => import("@/views/marketing/agent/collectDetail"),
    meta: {
      title: "项目回款审批详情",
      color: "#F7F8FA",
      goback: true,
    },
  },
  {
    path: "/payApprove",
    name: "payApprove",
    component: () => import("@/views/marketing/agent/payment/approve"),
    meta: {
      title: "审批",
      color: "#F7F8FA",
      source: "saleorder",
      goback: true,
    },
  },
  {
    path: "/payApproveDetail",
    name: "payApproveDetail",
    component: () => import("@/views/marketing/agent/payment/approveDetail"),
    meta: {
      title: "审批详情",
      color: "#F7F8FA",
      goback: true,
    },
  },
  {
    path: "/backDetail",
    name: "backDetail",
    component: () => import("@/views/marketing/agent/backDetail"),
    meta: {
      title: "退单审批详情",
      color: "#F7F8FA",
      goback: true,
    },
  },
  {
    path: "/agentAllot",
    name: "agentAllot",
    component: () => import("@/views/marketing/agent/agentAllot"),
    meta: {
      title: "待分配线索",
      color: "#F7F8FA",
      source: "saleclue",
      goback: true,
    },
  },
  {
    path: "/agentFollow",
    name: "agentFollow",
    component: () => import("@/views/marketing/agent/agentFollow"),
    meta: {
      title: "待跟进线索",
      color: "#F7F8FA",
      source: "saleclue",
      goback: true,
    },
  },
  // 外勤
  {
    path: "/fieldwork",
    name: "fieldwork",
    component: () => import("@/views/marketing/fieldwork/fieldwork"),
    meta: {
      title: "外勤",
      color: "#F7F8FA",
      goback: true, //返回首页
    },
  },
  // 外勤详情
  {
    path: "/fieldDetail",
    name: "fieldDetail",
    component: () => import("@/views/marketing/fieldwork/fieldworkDetail"),
    meta: {
      title: "外勤",
      color: "#F7F8FA",
      goback: true, //返回首页
    },
  },
  //
  {
    path: "/addFieldwork",
    name: "addFieldwork",
    component: () => import("@/views/marketing/fieldwork/addFieldwork"),
    meta: {
      title: "外勤申请",
      color: "#F7F8FA",
      goback: false, //返回首页
    },
  },
  // 移动端报表
  {
    path: "/mCensus",
    name: "mCensus",
    component: () => import("@/views/marketing/mReport/index"),
    meta: {
      title: "推广数据报表",
      color: "#F7F8FA",
      goback: false, //返回首页
    },
  },
  {
    path: "/mReport",
    name: "mReport",
    component: () => import("@/views/marketing/mReport/mReport"),
    meta: {
      title: "推广数据报表",
      color: "#ffffff",
      goback: false, //返回首页
    },
  },
  {
    path: "/mlist",
    name: "mlist",
    component: () => import("@/views/marketing/mReport/data_list"),
    meta: {
      title: "推广数据报表",
      color: "#F7F8FA",
      goback: false, //返回首页
    },
  },
  {
    path: "/mIncome",
    name: "mIncome",
    component: () => import("@/views/marketing/mReport/income"),
    meta: {
      title: "进线分析",
      color: "#F7F8FA",
      goback: false, //返回首页
    },
  },
  {
    path: "/mROI",
    name: "mROI",
    component: () => import("@/views/marketing/mReport/roi"),
    meta: {
      title: "投入产出比分析",
      color: "#F7F8FA",
      goback: false, //返回首页
    },
  },
];
