export default [
  // 职称首页
  {
    path: "/educate",
    name: "educate",
    component: () => import("@/views/educate/index"),
    meta: {
      title: "首页",
      color: "#ffffff",
      source: "professclue",
    },
  },
  {
    path: "/educateCount",
    name: "educateCount",
    component: () => import("@/views/educate/educateCount"),
    meta: {
      title: "统计",
      color: "#ffffff",
    },
  },
  {
    path: "/upcoming",
    name: "upcoming",
    component: () => import("@/views/educate/upcoming"),
    meta: {
      title: "待办",
      color: "#ffffff",
      source: "professclue",
    },
  },
  {
    path: "/achievement",
    name: "achievement",
    component: () => import("@/views/achievement/index"),
    meta: {
      title: "业绩看板",
      color: "#ffffff",
      source: "professclue",
    },
  },
  {
    path: "/marketingCensus",
    name: "marketingCensus",
    component: () => import("@/views/achievement/marketingCensus"),
    meta: {
      title: "营销看板",
      color: "#ffffff",
    },
  },
];
