// import tips from '../assets/js/tips'

import router from "@/router";
import store from "@/store";

import axios from "axios";
import Qs from "qs";
import { Notify } from "vant";
// import cookies from 'js-cookie';

function getContentType(config) {
  var type = config.contentType || "json";
  var charset = config.encoding || "UTF-8";
  if (config.url.indexOf("upload/uploadImage") !== -1) return "multipart/form-data;charset=" + charset;
  if (type === "json") {
    return "application/x-www-form-urlencoded;charset=" + charset;
  } else if (type === "form") {
    return "application/x-www-form-urlencoded;charset=" + charset;
  } else if (type === "file") {
    return "multipart/form-data;charset=" + charset;
  } else if (type === "text") {
    return "text/plain;charset=" + charset;
  } else if (type === "html") {
    return "text/html;charset=" + charset;
  } else {
    throw new Error("unsupported content type : " + type);
  }
}

axios.defaults.timeout = 5000;

//http request 拦截器
axios.interceptors.request.use(
  (config) => {
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = getContentType(config);
    }
    // const token = cookies.get('token') || ''; //注意使用的时候需要引入cookie方法，推荐js-cookie
    const token = store.state.token;

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    config.transformRequest = [
      function (data = {}, headers) {
        //依自己的需求对请求数据进行处理
        // data.token = token;
        // data.js_code = new Date().getTime();
        data = data.isUpload ? data : Qs.stringify(data);

        return data;
      },
    ];

    if (config.method === "get") {
      config.params = config.params || {};
      config.params = Object.assign(config.params, {
        // token: token,
      });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//http response 拦截器
axios.interceptors.response.use(
  (response) => {
    console.log(router.currentRoute.value.fullPath, "huang");
    if (response.data.code == 401) {
      localStorage.removeItem("token");
      router.push({
        name: "login",
        query: {
          redirect: router.currentRoute.value.fullPath,
        }, //从哪个页面跳转
      });
    }
    return response;
  },
  (err) => {
    // tips.loaded();
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          console.log("错误请求");
          Notify({
            type: "danger",
            message: err.response.data.message,
          });
          break;
        case 401:
          localStorage.removeItem("token");
          router.push({
            name: "login",
            query: {
              redirect: router.currentRoute.fullPath,
            }, //从哪个页面跳转
          });
          // login();
          break;
        case 403:
          console.log("拒绝访问");
          Notify({
            type: "danger",
            message: err.response.data.message,
          });

          break;
        case 404:
          console.log("请求错误,未找到该资源");
          Notify({
            type: "danger",
            message: err.response.data.message,
          });
          break;
        case 405:
          console.log("请求方法未允许");
          break;
        case 408:
          console.log("请求超时");
          break;
        case 500:
          console.log("服务器端出错");
          break;
        case 501:
          console.log("网络未实现");
          break;
        case 502:
          console.log("网络错误");
          break;
        case 503:
          console.log("服务不可用");
          break;
        case 504:
          console.log("网络超时");
          break;
        case 505:
          console.log("http版本不支持该请求");
          break;
        default:
          console.log(`连接错误${err.response.status}`);
      }
    } else {
      console.log("连接到服务器失败");
    }
    // .catch处理拿不到数据就没用reject
    return Promise.resolve(err.response);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then(
        (response) => {
          if (response.data.code == 200 || response.data.code == 1) {
            resolve(response.data);
          } else {
            // tips.error(response.data.msg);
            reject(response.data);
          }
        },
        (err) => {
          // reject(err)
        }
      )
      .catch((err) => {
        // reject(err)
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        if (response.data.code == 200 || response.data.code == 1) {
          resolve(response.data);
        } else {
          // tips.error(response.data.msg);
          reject(response.data);
        }
      },
      (err) => {
        // reject(err)
      }
    );
  });
}
