export default class LocalStorage {
  static get(key) {
    return localStorage.getItem(key);
  }

  static set(key, val) {
    localStorage.setItem(key, val);
  }

  static remove(key) {
    localStorage.removeItem(key);
  }

  static clear() {
    localStorage.clear();
  }
}
