import { post, fetch } from "../axios";
import host from "../config";

export default {
  //线索池列表
  getDecPoolList: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/declare`, data);
  },
  // 项目列表
  getDecProjectList: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/plist`, data);
  },
  //项目详情
  getDecProjectDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/pdetail`, data);
  },
  //项目分配
  decProjectAlloc: function (data = {}) {
    return post(`${host}/ypd/crm/project/alloc`, data);
  },
  //配证-项目需求信息
  decPcertify: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/pcertify`, data);
  },
  // 下证情况
  decTocerlst: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/tocerlst`, data);
  },
  //下证操作
  decTocertify: function (data = {}) {
    return post(`${host}/ypd/crm/project/tocertify`, data);
  },
  // 配证-添加配证需求
  decAddcert: function (data = {}) {
    return post(`${host}/ypd/crm/project/addcert`, data);
  },
  // 配证-发起配证
  decWfalloc: function (data = {}) {
    return post(`${host}/ypd/crm/project/wfalloc`, data);
  },
  // 配证-换人才
  decWftalent: function (data = {}) {
    return post(`${host}/ypd/crm/project/wftalent`, data);
  },

  //配证-撤出证书
  decRelease: function (data = {}) {
    return post(`${host}/ypd/crm/project/release`, data);
  },

  //配证-撤出证书
  delcert: function (data = {}) {
    return post(`${host}/ypd/crm/project/delcert`, data);
  },
  // 关单
  decColse: function (data = {}) {
    return post(`${host}/ypd/crm/project/close`, data);
  },
  // 回款-回款节点信息
  decPayinfo: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/payinfo`, data);
  },
  // 回款-添加回款
  decPayment: function (data = {}) {
    return post(`${host}/ypd/crm/project/payment`, data);
  },
  // 回款-项目配证操作
  decCertalloc: function (data = {}) {
    return post(`${host}/ypd/crm/project/certalloc`, data);
  },
  // 项目跟进-跟进列表
  decFlwuplist: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/flwuplist`, data);
  },
  // 项目跟进-跟进列表
  decFollowup: function (data = {}) {
    return post(`${host}/ypd/crm/project/followup`, data);
  },
  // 项目跟进-删除
  delFollowup: function (data = {}) {
    return post(`${host}/ypd/crm/project/followdel`, data);
  },
  // 项目跟进-预约
  delAppoints: function (data = {}) {
    return post(`${host}/ypd/crm/project/appoints`, data);
  },

  // 获取证书专业
  delCertifyCate: function (data = {}) {
    return fetch(`${host}/ypd/crm/certify/childcate`, data);
  },
  //项目配证完成
  finishAlloc: function (data = {}) {
    return post(`${host}/ypd/crm/project/finalloc`, data);
  },
  //项目配证完成
  finorder: function (data = {}) {
    return post(`${host}/ypd/crm/project/finorder`, data);
  },
  //删除回款审批
  delpayment: function (data = {}) {
    return post(`${host}/ypd/crm/project/delpayment`, data);
  },
};
