import { post, fetch } from "./axios";
import host from "./config";
import talent from "./talent/api";
import marketing from "./marketing/api";
import educate from "./educate/api";
import report from "./report/api";
import declare from "./declare/api";

// 公共
let api = {
  // 项目列表
  getProjectList: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/list`, data);
  },
  // 项目详情
  getProjectDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/detail`, data);
  },
  getCertdetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/certdetail`, data);
  },

  // 获取常量数据
  getConstant: function (data = {}) {
    return fetch(`${host}/ypd/crm/constant`, data);
  },
  // 获取证书分类
  getCerCategory: function (data = {}) {
    return fetch(`${host}/ypd/crm/certify/category`, data);
  },
  // 线索列表
  getClueList: function (data = {}) {
    return fetch(`${host}/ypd/crm/clue/list`, data);
  },
  //线索详情
  getClueDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/clue/detail`, data);
  },
  // 线索池
  getCluePool: function (data = {}) {
    return fetch(`${host}/ypd/crm/clue/pool`, data);
  },
  // 线索来源
  getCluePlatform: function (data = {}) {
    return fetch(`${host}/ypd/crm/clue/platform`, data);
  },

  getCommentList: function (data = {}) {
    return fetch(`${host}/ypd/crm/comment/list`, data);
  },
  // 增加评论
  clueComment: function (data = {}) {
    return post(`${host}/ypd/crm/clue/comment`, data);
  },
  // 删除评论
  delComment: function (data = {}) {
    return post(`${host}/ypd/crm/comment/del`, data);
  },

  // 线索转换
  clueTrans: function (data = {}) {
    return post(`${host}/ypd/crm/clue/trans`, data);
  },
  // 线索转移
  clueTransfer: function (data = {}) {
    return post(`${host}/ypd/crm/clue/transfer`, data);
  },
  //线索退回
  clueGiveback: function (data = {}) {
    return post(`${host}/ypd/crm/clue/giveback`, data);
  },
  // 线索丢弃
  clueDiscard: function (data = {}) {
    return post(`${host}/ypd/crm/clue/discard`, data);
  },
  // 线索编辑
  clueEdit: function (data = {}) {
    return post(`${host}/ypd/crm/clue/edit`, data);
  },
  // 组织架构
  getUserList: function (data = {}) {
    return post(`${host}/ypd/crm/user/list`, data);
  },
  // 分配线索
  poolShare: function (data = {}) {
    return post(`${host}/ypd/crm/pool/share`, data);
  },
  // 线索领取
  poolClaim: function (data = {}) {
    return post(`${host}/ypd/crm/pool/claim`, data);
  },
  // 配项目列表
  getProjectListing: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/listing`, data);
  },
  // 获取项目配证需求
  getProjectCertify: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/certify`, data);
  },
  // 获取用户权限
  getUserAuth: function (data = {}) {
    return fetch(`${host}/ypd/crm/user/auth`, data);
  },
  // 获取团队目标
  getTargetTeam: function (data = {}) {
    return fetch(`${host}/ypd/crm/target/team`, data);
  },
  getTargetAuth: function (data = {}) {
    return fetch(`${host}/ypd/crm/target/auth`, data);
  },
  getTargetDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/target/detail`, data);
  },
  getTargetEdit: function (data = {}) {
    return post(`${host}/ypd/crm/target/editeam`, data);
  },
  getTargetUsers: function (data = {}) {
    return fetch(`${host}/ypd/crm/target/userlist`, data);
  },
  getTargetRanking: function (data = {}) {
    return fetch(`${host}/ypd/crm/target/ranking`, data);
  },
  getToken: function (data = {}) {
    return fetch(`${host}/ypd/report/login`, data);
  },

  // ++++++++++++++++++++++++++++++++++
  //批量分配
  projectBatchalloc: function (data = {}) {
    return post(`${host}/ypd/crm/project/batchalloc`, data);
  },
  // 征用审批列表
  projectGetcodlist: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/getcodlist`, data);
  },

  // 征用审批列表
  projectCodsubmit: function (data = {}) {
    return post(`${host}/ypd/crm/project/codsubmit`, data);
  },
  // 征用审批列表
  projectCodetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/codetail`, data);
  },
  projectAuth: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/itemauth`, data);
  },

  // ++++++++++++++++++++++++++++++++++

  // 上传图片
  uploadImage: function (data = {}) {
    data.isUpload = "upload";
    return post(`${host}/ypd/upload/uploadImage`, data);
  },
  uploadFile: function (data = {}) {
    data.isUpload = "upload";
    return post(`${host}/ypd/upload/uploadFile`, data);
  },
  uploadImageUrl: `${host}/ypd/upload/uploadImage`,
  loginUrl: `${host}/ypd/report/oauth`,
};
api = Object.assign(api, talent);
api = Object.assign(api, marketing);
api = Object.assign(api, educate);
api = Object.assign(api, report);
api = Object.assign(api, declare); //申报

export default api;
