import api from "../api/api";
import { createStore } from "vuex";

export default createStore({
  state: {
    token: null,
    clueInfo: [],
    cluePlatform: [],
    constant: {
      certificate_status: [],
      talent_type: [],
      contract_term: [],
      item_security: [],
      certificate_b: [],
      clue_type: [],
      valid_state: [],
      cooperate_way: [],
      qualify_type: [],
      certificate_type: [],
      professional: [],
      certificate_way: [],
      platform: [],
      sale_platform: [],
      validity_status: [],
      visit_type: [],
      stu_education: [],
      item_state: [], //项目状态
      charge_way: [],
      bill_type: [],
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setClueInfo(state, array) {
      state.clueInfo = [...array];
    },
    // setCluePlatform(state, array) {
    //   state.cluePlatform = [...array];
    // },
    setConstant(state, obj) {
      state.constant = obj;
    },
  },
  actions: {
    // 线索池
    actionsClueInfo({ commit }) {
      return new Promise((resolve, reject) => {
        //获取分类
        api.getCluePool().then((res) => {
          commit("setClueInfo", res.data);
          resolve();
        });
      });
    },
    // // 线索来源
    // actionsCluePlatform({
    //   commit
    // }) {
    //   return new Promise((resolve, reject) => {
    //     //获取分类
    //     api.getCluePlatform().then((res) => {
    //       commit('setCluePlatform', res.data);
    //       resolve();
    //     })
    //   })
    // },
    // 一些常量
    actionsConstant({ commit }) {
      return new Promise((resolve, reject) => {
        //获取分类
        api.getConstant().then((res) => {
          commit("setConstant", res.data);
          resolve();
        });
      });
    },
  },
  modules: {},
});
