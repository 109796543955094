import { post, fetch } from "../axios";
import host from "../config";

export default {
  //线索池列表
  getPoolList: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleclue/list`, data);
  },
  // 销售线索列表
  getSaleList: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleclue/salelist`, data);
  },
  // 销售线索详情
  getsaleclueDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleclue/detail`, data);
  },
  // 销售线索编辑
  saleclueEdit: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/edit`, data);
  },
  // 评论列表
  getMCommentlist: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleclue/commentlist`, data);
  },
  //添加评论
  mAddComment: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/addcomment`, data);
  },
  //删除评论
  mDelComment: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/delcomment`, data);
  },
  // 公海列表
  getSeasList: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleclue/seaslist`, data);
  },
  // 获取线索池线索
  getSalecluePool: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleclue/pool`, data);
  },
  // // 线索转换
  // saleclueTransform: function (data = {}) {
  //     return post(`${host}/ypd/crm/saleclue/transform`, data)
  // },
  // 线索退回
  saleclueGoback: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/goback`, data);
  },
  // 线索废弃
  saleclueAbandon: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/abandon`, data);
  },
  // 无效线索
  saleclueInvalid: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/invalid`, data);
  },
  // 线索转移
  saleclueDivert: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/divert`, data);
  },
  // 线索分配
  saleclueShareClue: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/shareclue`, data);
  },
  // 领取
  saleclueClaimclue: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/claimclue`, data);
  },

  //  获取预设对象
  getPresetCategory: function (data = {}) {
    return fetch(`${host}/ypd/crm/preset/category`, data);
  },
  //预约跟进
  saleclueAppoints: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/appoints`, data);
  },
  // 客户预约
  customerAppoints: function (data = {}) {
    return post(`${host}/ypd/crm/salecustom/appoints`, data);
  },
  //消息为阅统计跟进

  messageCounts: function (data = {}) {
    return fetch(`${host}/ypd/crm/message/counts`, data);
  },
  // 消息线索列表
  messageList: function (data = {}) {
    return fetch(`${host}/ypd/crm/message/list`, data);
  },
  // 设置消息已阅
  messageRead: function (data = {}) {
    return post(`${host}/ypd/crm/message/read`, data);
  },

  //线索统计
  getMStatistics: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleteam/census`, data);
  },
  //销售排行榜
  getMTargetCharts: function (data = {}) {
    return fetch(`${host}/ypd/crm/target/charts`, data);
  },
  //个人线索统计
  getBriefing: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleclue/briefing`, data);
  },

  // 目标接口
  getMTarget: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleteam/target`, data);
  },
  getMTargetDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleteam/depdetail`, data);
  },
  getMPersonDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleteam/persondetail`, data);
  },
  getMTargetEdit: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleteam/depedit`, data);
  },

  // 客户
  getCustomer: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleteam/custom`, data);
  },
  // 转客户
  changeCustomer: function (data = {}) {
    return post(`${host}/ypd/crm/saleteam/changecustom`, data);
  },
  // 编辑
  eidtCustomer: function (data = {}) {
    return post(`${host}/ypd/crm/saleteam/editcustomer`, data);
  },
  // 客户详情
  getCustomerDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleteam/customdetail`, data);
  },

  // 合同详情
  getMContractDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleteam/contractdetail`, data);
  },
  // 修改合同详情
  mContractEdit: function (data = {}) {
    return post(`${host}/ypd/crm/saleteam/contractedit`, data);
  },
  // 添加合同
  mAddCustomer: function (data = {}) {
    return post(`${host}/ypd/crm/saleteam/addcustomer`, data);
  },
  // 跟进
  addCustomerComment: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/addcomment/customer`, data);
  },
  //团队人员
  getMtargetUser: function (data = {}) {
    return fetch(`${host}/ypd/crm/target/teamuser`, data);
  },
  //权限判断
  getMClueauth: function (data = {}) {
    return fetch(`${host}/ypd/crm/common/clueauth`, data);
  },

  // 审批接口
  // 获取流程列表
  getWorkflowList: function (data = {}) {
    return fetch(`${host}/ypd/crm/workflow/list`, data);
  },
  // 提交审批
  getWorkflowSubmit: function (data = {}) {
    return fetch(`${host}/ypd/crm/workflow/submit`, data);
  },
  // 审批详情
  getWorkflowDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/workflow/detail`, data);
  },

  // 项目立项表
  getProjectForm: function (data = {}) {
    if (localStorage.getItem("isUpdate")) data.is_update = localStorage.getItem("isUpdate"); //更新表
    return fetch(`${host}/ypd/crm/saleteam/projectform`, data);
  },
  // 人才明细表
  getTalentForm: function (data = {}) {
    if (localStorage.getItem("isUpdate")) data.is_update = localStorage.getItem("isUpdate"); //更新表
    return fetch(`${host}/ypd/crm/saleteam/talentlist`, data);
  },
  // 退单调减表单
  chargeReduct: function (data = {}) {
    return fetch(`${host}/ypd/crm/saleteam/chargeReduct`, data);
  },
  // test
  wxbaseoauth: function (data = {}) {
    return fetch(`https://admin.t.youpudao.cn/api/common/wxshare`, data);
  },

  //外勤列表
  getFieldWorkList: function (data = {}) {
    return fetch(`${host}/ypd/crm/fieldlog/getfieldlist`, data);
  },
  // 外勤详情
  getFieldWorkDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/fieldlog/fielddetail`, data);
  },
  // 添加详情
  addFieldWork: function (data = {}) {
    return post(`${host}/ypd/crm/fieldlog/addfieldlog`, data);
  },
  // 获取审批节点(废弃)
  getFieldWorkPoint: function (data = {}) {
    return fetch(`${host}/ypd/crm/fieldlog/getuserlog`, data);
  },
  // 撤销接口
  removeFieldWork: function (data = {}) {
    return post(`${host}/ypd/crm/fieldlog/delfieldlog`, data);
  },
  // 外勤审批
  applyFieldWork: function (data = {}) {
    return post(`${host}/ypd/crm/fieldlog/submit`, data);
  },
  // 外勤权限
  fieldWorkAuth: function (data = {}) {
    return fetch(`${host}/ypd/crm/fieldlog/userauth`, data);
  },
  // 外勤统计
  fieldWorkCount: function (data = {}) {
    return fetch(`${host}/ypd/crm/fieldlog/counts`, data);
  },

  // 解锁接口
  changeUnlock: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/unlock`, data);
  },
  // 锁定
  changeLock: function (data = {}) {
    return post(`${host}/ypd/crm/saleclue/locking`, data);
  },

  //调减
  approvalReduction: function (data = {}) {
    return post(`${host}/ypd/crm/preform/reduce`, data);
  },
  // 退单
  approvalBack: function (data = {}) {
    return post(`${host}/ypd/crm/preform/changeback`, data);
  },
  // 审批
  approval: function (data = {}) {
    return post(`${host}/ypd/crm/preform/submit`, data);
  },
  // 提醒
  reminder: function (data = {}) {
    return fetch(`${host}/ypd/crm/preform/reminder`, data);
  },
  // 审批列表
  approvalList: function (data = {}) {
    return fetch(`${host}/ypd/crm/preform/list`, data);
  },
  approvalDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/preform/detail`, data);
  },
  //外勤统计
  getApproveCount: function (data = {}) {
    return fetch(`${host}/ypd/crm/preform/counts`, data);
  },
  //外勤统计
  getPaymentCount: function (data = {}) {
    return fetch(`${host}/ypd/crm/payapproval/counts`, data);
  },
  // 有效线索统计
  saleclueCensus: function (data = {}) {
    return fetch(`${host}/ypd/report/census`, data);
  },

  // ========人事start==========================
  crmbPoolApi: function (data = {}) {
    return fetch(`${host}/ypd/crmb/saleclue/plist`, data);
  },
  crmbCensuslistApi: function (data = {}) {
    return fetch(`${host}/ypd/crmb/saleclue/censuslist`, data);
  },
  crmbCustomerlistApi: function (data = {}) {
    return fetch(`${host}/ypd/crmb/customer/censuslist`, data);
  },
  crmbFollowlistApi: function (data = {}) {
    return fetch(`${host}/ypd/crmb/saleclue/flist`, data);
  },
  crmbExportSaleclueApi: `${host}/ypd/crmb/saleclue/export`,
  crmbExportApi: `${host}/ypd/crmb/customer/export`,
  crmbExportFollowerApi: `${host}/ypd/crmb/follower/export`,

  // ========人事end==========================

  // ========合同编号start==========================

  // 创建合同编号
  createNumApi: function (data = {}) {
    return post(`${host}/ypd/crm/contractnumber/createnumber`, data);
  },
  // 合同编号列表
  numListApi: function (data = {}) {
    return fetch(`${host}/ypd/crm/contractnumber/list`, data);
  },
  // 删除合同编号
  delNumApi: function (data = {}) {
    return post(`${host}/ypd/crm/contractnumber/delnumber`, data);
  },
  // ========合同编号end==========================

  // ========数据统计start==========================

  // 数据看板
  getPerformance: function (data = {}) {
    return fetch(`${host}/ypd/crm/preform/getsingle`, data);
  },
  // 数据看板
  topreport: function (data = {}) {
    return fetch(`${host}/ypd/crm/preform/topreport`, data);
  },
  // 数据看板
  annularcharts: function (data = {}) {
    return fetch(`${host}/ypd/crm/preform/annularcharts`, data);
  },
  // 数据看板
  percharts: function (data = {}) {
    return fetch(`${host}/ypd/crm/preform/percharts`, data);
  },

  // 分公司PK业绩统计
  getPkstat: function (data = {}) {
    return fetch(`${host}/ypd/crm/perform/pkstat`, data);
  },
  // 个人有效业绩排行
  getPersonstat: function (data = {}) {
    return fetch(`${host}/ypd/crm/perform/personstat`, data);
  },
  // 签约目标与进度
  getSignstat: function (data = {}) {
    return fetch(`${host}/ypd/crm/perform/signstat`, data);
  },
  // 签约目标与进度详情数据
  getSigndetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/perform/signdetail`, data);
  },

  // ========数据统计end==========================

  // 营销付款申请
  makPayment: function (data = {}) {
    return post(`${host}/ypd/crm/payapproval/payment`, data);
  },
  // 营销付款审批
  makPaymentSubmit: function (data = {}) {
    return post(`${host}/ypd/crm/payapproval/submit`, data);
  },
  // 营销付款列表
  makPaymentList: function (data = {}) {
    return fetch(`${host}/ypd/crm/payapproval/list`, data);
  },
  // 营销付款审批详情
  makPaymentDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/payapproval/detail`, data);
  },

  // 通过营销线索池获取绑定的申报池
  getBindPool: function (data = {}) {
    return fetch(`${host}/ypd/crm/pool/exchange`, data);
  },
};
