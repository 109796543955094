export default [
  // 申报首页
  {
    path: "/declare",
    name: "declare",
    component: () => import("@/views/declare/index"),
    meta: {
      title: "首页",
      color: "#ffffff",
      source: "declare",
    },
  },
  {
    path: "/deal",
    name: "deal",
    component: () => import("@/views/declare/deal"),
    meta: {
      title: "待办",
      color: "#ffffff",
      source: "declare",
    },
  },
  {
    path: "/delApprove",
    name: "delApprove",
    component: () => import("@/views/declare/approve"),
    meta: {
      title: "审批详情",
      color: "#ffffff",
      source: "declare",
    },
  },
];
