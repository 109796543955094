import { createRouter, createWebHistory } from "vue-router";
import marketing from "./marketing/index";
import educate from "./educate/index";
import declare from "./declare/index";
let routes = [
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/error/404"),
    meta: {
      isNoFooter: true,
    },
  },
  //重定向
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/index/login"),
    meta: {
      title: "登陆",
      color: "#F7F8FA",
    },
  },
  {
    path: "/pc_hunting",
    name: "pc_hunting",
    component: () => import("@/views/hunting/pc/index"),
    meta: {
      title: "首页",
      color: "#ffffff",
    },
  },
  //首页
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index/index"),
    meta: {
      title: "首页",
      color: "#F7F8FA",
    },
  },
  {
    path: "/ranking",
    name: "ranking",
    component: () => import("@/views/index/ranking"),
    meta: {
      title: "首页",
      color: "#F7F8FA",
    },
  },
  {
    path: "/targetAdd",
    name: "targetAdd",
    component: () => import("@/views/index/targetAdd"),
    meta: {
      title: "添加团队目标",
      color: "#F7F8FA",
    },
  },
  //项目需求
  {
    path: "/demand",
    name: "demand",
    component: () => import("@/views/demand/demand"),
    meta: {
      title: "项目需求",
      color: "#F7F8FA",
    },
  },
  //需求详情
  {
    path: "/demandDetail",
    name: "demandDetail",
    component: () => import("@/views/demand/demandDetail"),
    meta: {
      title: "需求详情",
      color: "#F7F8FA",
    },
  },
  //项目配证
  {
    path: "/demandAllocate",
    name: "demandAllocate",
    component: () => import("@/views/demand/allocate"),
    meta: {
      title: "项目配证",
      color: "#F7F8FA",
    },
  },
  // 线索
  {
    path: "/clue",
    name: "clue",
    component: () => import("@/views/clue/clue"),
    meta: {
      title: "线索",
      color: "#F7F8FA",
    },
  },
  // 线索
  {
    path: "/clueAdd",
    name: "clueAdd",
    component: () => import("@/views/clue/clueAdd"),
    meta: {
      title: "添加线索",
      color: "#F7F8FA",
    },
  },
  // 线索详情
  {
    path: "/clueDetail",
    name: "clueDetail",
    component: () => import("@/views/clue/clueDetail"),
    meta: {
      title: "线索详情",
      color: "#F7F8FA",
    },
  },
  {
    path: "/clueDrop",
    name: "clueDrop",
    component: () => import("@/views/clue/clueDrop"),
    meta: {
      title: "丢弃线索",
      color: "#F7F8FA",
    },
  },
  {
    path: "/clueRemove",
    name: "clueRemove",
    component: () => import("@/views/clue/clueRemove"),
    meta: {
      title: "丢弃线索",
      color: "#F7F8FA",
    },
  },
  // 人才
  {
    path: "/talent",
    name: "talent",
    component: () => import("@/views/talent/talent"),
    meta: {
      title: "人才",
      color: "#F7F8FA",
    },
  },
  // 线索详情
  {
    path: "/talentDetail",
    name: "talentDetail",
    component: () => import("@/views/talent/talentDetail"),
    meta: {
      title: "人才详情",
      color: "#F7F8FA",
    },
  },
  {
    path: "/talentSign",
    name: "talentSign",
    component: () => import("@/views/talent/talentSign"),
    meta: {
      title: "人才签约",
      color: "#F7F8FA",
    },
  },
  {
    path: "/talentSale",
    name: "talentSale",
    component: () => import("@/views/talent/talentSale"),
    meta: {
      title: "企业单",
      color: "#F7F8FA",
    },
  },
  {
    path: "/talentAdd",
    name: "talentAdd",
    component: () => import("@/views/talent/talentAdd"),
    meta: {
      title: "添加人才",
      color: "#F7F8FA",
    },
  },
  {
    path: "/contract",
    name: "contract",
    component: () => import("@/views/talent/contract"),
    meta: {
      title: "合同详情",
      color: "#F7F8FA",
    },
  },
  {
    path: "/publicTalent",
    name: "publicTalent",
    component: () => import("@/views/publicTalent/publicTalent"),
    meta: {
      title: "添加证书",
      color: "#F7F8FA",
    },
  },
  {
    path: "/searchTalent",
    name: "searchTalent",
    component: () => import("@/views/searchTalent/searchTalent"),
    meta: {
      title: "人才查询",
      color: "#F7F8FA",
    },
  },
  {
    path: "/pending",
    name: "pending",
    component: () => import("@/views/pending/index"),
    meta: {
      title: "待办",
      color: "#F7F8FA",
    },
  },
  {
    path: "/huntApprove",
    name: "huntApprove",
    component: () => import("@/views/pending/approve"),
    meta: {
      title: "审批",
      color: "#F7F8FA",
    },
  },
  {
    path: "/huntApproveDetail",
    name: "huntApproveDetail",
    component: () => import("@/views/pending/approveDetail"),
    meta: {
      title: "审批详情",
      color: "#F7F8FA",
    },
  },
  {
    path: "/talentApprove",
    name: "talentApprove",
    component: () => import("@/views/pending/talentApprove"),
    meta: {
      title: "审批",
      color: "#F7F8FA",
    },
  },
  {
    path: "/talentApproveDetail",
    name: "talentApproveDetail",
    component: () => import("@/views/pending/talentApproveDetail"),
    meta: {
      title: "审批详情",
      color: "#F7F8FA",
    },
  },
  {
    path: "/reduceApproveDetail",
    name: "reduceApproveDetail",
    component: () => import("@/views/pending/reduceApproveDetail"),
    meta: {
      title: "调减审批详情",
      color: "#F7F8FA",
    },
  },
  {
    path: "/preview",
    name: "preview",
    component: () => import("@/views/public/preview"),
    meta: {
      title: "文件预览",
      color: "#F7F8FA",
    },
  },
];

routes = routes.concat(marketing);
routes = routes.concat(educate);
routes = routes.concat(declare);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  document.body.style.background = to.meta.color ? to.meta.color : "#ffffff";
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
