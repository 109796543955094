import { post, fetch } from "../axios";
import host from "../config";

export default {
  // test: function (data = {}) {
  //   return fetch(`http://192.168.18.78:8091/api/advert/getlist?relation_name=index_banner`)
  // },
  //获取人才列表
  getTalentList: function (data = {}) {
    return fetch(`${host}/ypd/crm/talent/list`, data);
  },
  // 人才证书编辑
  talentEdit: function (data = {}) {
    return post(`${host}/ypd/crm/talent/edit`, data);
  },
  // 人才续约
  talentRenewal: function (data = {}) {
    return post(`${host}/ypd/crm/talent/renewal`, data);
  },
  // 人才归还
  talentRefund: function (data = {}) {
    return post(`${host}/ypd/crm/talent/refund`, data);
  },
  talentResale: function (data = {}) {
    return post(`${host}/ypd/crm/talent/resale`, data);
  },
  //人才详情
  getTalentDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/talent/detail`, data);
  },
  //人才合同
  getTalentContract: function (data = {}) {
    return fetch(`${host}/ypd/crm/talent/contract`, data);
  },
  //人才合同详情
  getContractDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/talent/condetail`, data);
  },
  // 人才详情
  contractEdit: function (data = {}) {
    return post(`${host}/ypd/crm/talent/conedit`, data);
  },
  // 人才动态
  getTalentComment: function (data = {}) {
    return fetch(`${host}/ypd/crm/talent/comment`, data);
  },
  // 人才库
  getCertifyStock: function (data = {}) {
    return fetch(`${host}/ypd/crm/certify/stock`, data);
  },
  getTalentpayinfo: function (data = {}) {
    return fetch(`${host}/ypd/crm/project/talentpayinfo`, data);
  },
  // 人才企业单调减
  setTalentReduce: function (data = {}) {
    return post(`${host}/ypd/crm/talent/enterdecrease`, data);
  },
  // 人才企业单调减列表
  getTalentReduceList: function (data = {}) {
    return fetch(`${host}/ypd/crm/preform/enterlist`, data);
  },
  // 人才企业单调减详情
  getTalentReduceDetail: function (data = {}) {
    return fetch(`${host}/ypd/crm/preform/enterdetail`, data);
  },
};
