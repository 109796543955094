import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import utils from "@/assets/js/utils";
import localStorage from "@/assets/js/localStorage";
import api from "./api/api";
import Vant from "vant"; //移动端
import "vant/lib/index.css";
import "@vant/touch-emulator";
import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
// import ViewUI from 'view-design';
// import 'view-design/dist/styles/iview.css';
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";
let token = localStorage.get("token") ? true : false;
if (token) {
  store.commit("setToken", localStorage.get("token"));
  store.dispatch("actionsClueInfo");
  // store.dispatch("actionsCluePlatform");
  store.dispatch("actionsConstant");
}

const app = createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(Vant)
  .use(VXETable);

app.config.globalProperties.$utils = utils;
app.config.globalProperties.$local = localStorage;
app.config.globalProperties.$api = api;
app.config.globalProperties.$XModal = VXETable.modal;

app.mount("#app");
