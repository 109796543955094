import {
    post,
    fetch
} from '../axios'
import host from '../config'

export default {
    //线索池列表
    getProPoolList: function (data = {}) {
        return fetch(`${host}/ypd/crm/proclue/list`, data)
    },
    // 销售线索列表
    getProclueList: function (data = {}) {
        return fetch(`${host}/ypd/crm/proclue/prolist`, data)
    },
    // 销售线索详情
    getProclueDetail: function (data = {}) {
        return fetch(`${host}/ypd/crm/proclue/detail`, data)
    },
    // 销售线索编辑
    proclueEdit: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/edit`, data)
    },
    // 评论列表
    getProclueComment: function (data = {}) {
        return fetch(`${host}/ypd/crm/proclue/commentlist`, data)
    },
    //添加评论
    pAddComment: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/addcomment`, data)
    },
    //删除评论
    pDelComment: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/delcomment`, data)
    },
    // 公海列表
    proclueSeasList: function (data = {}) {
        return fetch(`${host}/ypd/crm/proclue/seaslist`, data)
    },
    // 获取线索池线索
    getProcluePool: function (data = {}) {
        return fetch(`${host}/ypd/crm/proclue/pool`, data)
    },
    // // 线索转换
    // saleclueTransform: function (data = {}) {
    //     return post(`${host}/ypd/crm/proclue/transform`, data)
    // },
    // 线索退回
    proclueGoback: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/goback`, data)
    },
    // 线索废弃
    proclueAbandon: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/abandon`, data)
    },
    // 无效线索
    proclueInvalid: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/invalid`, data)
    },
    // 线索转化
    proclueTransform: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/transform`, data)
    },
    // 线索转移
    proclueDivert: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/divert`, data)
    },
    // 线索分配
    proclueShareClue: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/shareclue`, data)
    },
    // 领取
    proclueClaimclue: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/claimclue`, data)
    },

    //  获取预设对象
    getPresetCategory: function (data = {}) {
        return fetch(`${host}/ypd/crm/preset/category`, data)
    },
    //预约跟进
    proclueAppoints: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/appoints`, data)
    },
    // 客户预约
    customerAppoints: function (data = {}) {
        return post(`${host}/ypd/crm/salecustom/appoints`, data)
    },
    //消息为阅统计跟进

    messageCounts: function (data = {}) {
        return fetch(`${host}/ypd/crm/message/counts`, data)
    },
    // 消息线索列表
    messageList: function (data = {}) {
        return fetch(`${host}/ypd/crm/message/list`, data)
    },
    // 设置消息已阅
    messageRead: function (data = {}) {
        return post(`${host}/ypd/crm/message/read`, data)
    },
    proclueBriefing: function (data = {}) {
        return fetch(`${host}/ypd/crm/proclue/briefing`, data)
    },


    //学员
    prostuList: function (data = {}) {
        return fetch(`${host}/ypd/crm/prostu/list`, data)
    },
    //学员编辑
    prostuEdit: function (data = {}) {
        return post(`${host}/ypd/crm/prostu/edit`, data)
    },
    //学员
    prostuDetail: function (data = {}) {
        return fetch(`${host}/ypd/crm/prostu/detail`, data)
    },

    prostuCategory: function (data = {}) {
        return fetch(`${host}/ypd/crm/prostu/category`, data)
    },
    // 订单列表
    prostuOrderList: function (data = {}) {
        return fetch(`${host}/ypd/crm/prostu/orderlist`, data)
    },
    // 订单
    prostuOrder: function (data = {}) {
        return fetch(`${host}/ypd/crm/prostu/order`, data)
    },



    // 解锁接口
    pChangeUnlock: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/unlock`, data)
    },
    // 锁定
    pChangeLock: function (data = {}) {
        return post(`${host}/ypd/crm/proclue/locking`, data)
    },




    //===================学员接口=================================
    // 储备人才
    prostuReserve: function (data = {}) {
        return fetch(`${host}/ypd/crm/prostu/reserve`, data)
    },
    // 学员转储备人才
    prostuChange: function (data = {}) {
        return fetch(`${host}/ypd/crm/prostu/change`, data)
    },
    // 人才储备回复
    prostuReply: function (data = {}) {
        return fetch(`${host}/ypd/crm/prostu/reply`, data)
    },

    //===================学员接口=================================


}